import { RecordSource, RelayFeatureFlags, Environment } from 'relay-runtime'
import RelayModernStore from 'relay-runtime/lib/store/RelayModernStore'
import { v4 as uuid } from 'uuid'

import storage from '@src/api/storage'
import { API_URLS, APP_BUILD_NUMBER, IS_ALPHA, IS_DEV, VERSION } from '@src/config'
import { JOBS_KEY } from '@src/constants/api'
import { logFunction } from '@src/graphql/logger'
import { vConsoleMiddleware } from '@src/graphql/middlewares/vConsole'
import { MiddlewareNextFn } from '@src/types/relay'

import { errorMiddleware } from './middlewares/error'
import { perfMiddleware } from './middlewares/perf'
import { retryMiddleware } from './middlewares/retry'
import { urlMiddleware } from './middlewares/url'
import { createCustomNetwork } from './network'

const baseUrl = { value: API_URLS[JOBS_KEY] }
const source = new RecordSource()
export const store = new RelayModernStore(source)
RelayFeatureFlags.ENABLE_RELAY_RESOLVERS = true

let environment: Environment

export const setBaseUrl = (newUrl: string) => {
  baseUrl.value = newUrl
  // eslint-disable-next-line no-console
  console.log('api endpoint changed', baseUrl.value)
}

function createNetwork() {
  return createCustomNetwork({
    middlewares: [
      urlMiddleware({
        url: Promise.resolve(`${baseUrl.value}/graphql`),
        headers: {
          'apollographql-client-name': 'jobs-client',
          'apollographql-client-version': String(APP_BUILD_NUMBER),
          'x-client-version': VERSION,
        },
      }),
      IS_ALPHA ? errorMiddleware() : null,
      IS_ALPHA ? perfMiddleware() : null,
      IS_ALPHA ? vConsoleMiddleware() : null,
      retryMiddleware({
        fetchTimeout: 5000,
        retryDelays: [3200, 5000, 5000],
        beforeRetry: ({ forceRetry }) => {
          window.forceRelayRetry = forceRetry
        },
      }),
      (next: MiddlewareNextFn) => async (req) => {
        const authToken = storage.getToken()
        const userAgent = storage.getUserAgent()
        const { referrer } = storage.getReferrerInfo()

        // legacy - biz
        // const bizAccessToken = storage.getBizToken()
        // if (bizAccessToken) req.fetchOpts.headers['x-biz-access-token'] = bizAccessToken

        if (authToken) req.headers['x-auth-token'] = authToken
        if (userAgent) req.headers['x-user-agent'] = userAgent
        if (referrer) req.headers['x-referrer'] = referrer
        req.headers['x-request-id'] = uuid()

        return next(req)
      },
    ],
  })
}

function relayFieldLogger(event: any) {
  if (!IS_DEV) return

  if (event.kind === 'relay_resolver.error') {
    // Log this somewhere!
    console.warn(`Resolver error encountered in ${event.owner}.${event.fieldPath}`)
    console.warn(event.error, event.name, event.operationName)
  }
}

export function createEnvironment() {
  if (environment) return environment

  return new Environment({
    network: createNetwork(),
    store,
    log: logFunction(),
    relayFieldLogger,
  })
}
