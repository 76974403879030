import { Environment } from 'relay-runtime'

const MAX_REQUESTS = 20

export class RelayPluginModel {
  private static instance: RelayPluginModel
  private requests: any[] = []
  public maxRequests: number = MAX_REQUESTS
  public environment: Environment | null = null

  static getSingleton(): RelayPluginModel {
    if (!this.instance) {
      this.instance = new RelayPluginModel()
    }
    return this.instance
  }

  setEnvironment(environment: Environment) {
    this.environment = environment
  }

  addRequest(request: any) {
    this.requests.unshift(request)
    if (this.requests.length > this.maxRequests) {
      this.requests.pop()
    }
  }

  getRequests() {
    return this.requests
  }

  clearRequests() {
    this.requests = []
  }

  getStore() {
    if (!this.environment) return null
    return (this.environment.getStore() as any).getSource().toJSON()
  }
}

export const relayPluginModel = RelayPluginModel.getSingleton()
