import { format, isToday, isTomorrow, startOfDay } from 'date-fns'
import { includes } from 'lodash'
import { map, pick, pipe } from 'lodash/fp'

import storage from '@src/api/storage'
import { BUSINESS_FORM_VALUES, CREATE_JOB_POST_FORM, RECENTLY_JOB_POST_ADDRESS } from '@src/constants/api'
import { CURRENT_MIN_SALARY } from '@src/constants/business'
import {
  jobPostIninitialValues,
  jobTaskValues,
  personalJobTaskValues,
  salaryTypeValues,
  workDayValues,
  workPeriodValues,
  workTimeValues,
} from '@src/constants/form'
import { captureException } from '@src/sdks/sentry'
import { AllJobTaskType, JobPostServerInputs, JobPostType, JobTaskType } from '@src/types/jobPost'
import { ExperienceType, ResumeServerInputs, ResumeType } from '@src/types/resume'
import { isContinualDates, isPastDate, parseDate, sortDates } from '@src/utils/date'

import { transformFields } from './data'
import { priceFormat } from './number'
import { mapWorkDay, setEmptyStringToNull, sortJobTasks, sortWorkDays } from './string'
import { isStringArray } from './validation'

// ------------------------------------------------------------------------------------
// JobPost Form
// ------------------------------------------------------------------------------------

/**
 * LocalStorage Data 초기화
 */
export const clearJobPostFormDataAtLocal = (customKey?: string) => {
  storage.removeItemAtLocal(customKey ?? CREATE_JOB_POST_FORM)
}

/**
 * WorkDates Stringify
 */
export const stringifyWorkDates = (workDates: Date[] | string[]) => {
  if (!workDates.length) return ''

  let handledWorkDates: Date[]

  if (isStringArray(workDates)) {
    handledWorkDates = workDates.map((date) => parseDate(date, 'yyyy-MM-dd'))
  } else {
    handledWorkDates = workDates
  }

  handledWorkDates = sortDates(handledWorkDates)
  const isDatesContinual = isContinualDates(handledWorkDates)

  const format = (d: Date) => {
    switch (true) {
      case isToday(d):
        return '오늘'
      case isTomorrow(d):
        return '내일'
      default:
        return `${d.getMonth() + 1}월 ${d.getDate()}일`
    }
  }

  if (handledWorkDates.length === 1) {
    const isTodayDate = isToday(handledWorkDates[0])
    const formattedDate = format(handledWorkDates[0])
    return isTodayDate ? formattedDate : `${formattedDate}(${mapWorkDay(handledWorkDates[0].getDay())})`
  }

  let datesString
  const [start, end] = [handledWorkDates[0], handledWorkDates[handledWorkDates.length - 1]]
  if (start.getMonth() === end.getMonth() && !isToday(start) && !isTomorrow(end)) {
    datesString = `${start.getMonth() + 1}월 ${start.getDate()}~${end.getDate()}일`
  } else {
    datesString = `${format(start)}~${format(end)}`
  }

  return `총 ${handledWorkDates.length}일 / ${datesString}${isDatesContinual ? '' : ' 중'}`
}

/**
 * Salary Type 관리
 */
export const salaryMappers = (() => {
  const mappers = {
    HOURLY: {
      formatter: (d: number) => d,
      unitLabel: '시급',
      priceLabel: '원',
      pricePlaceHolder: String(priceFormat(CURRENT_MIN_SALARY) ?? ''),
    },
    PER_CASE: {
      formatter: (d: number) => d,
      unitLabel: '건당',
      priceLabel: '원',
      pricePlaceHolder: '0',
    },
    DAILY: {
      formatter: (d: number) => d,
      unitLabel: '일급',
      priceLabel: '원',
      pricePlaceHolder: '0',
    },
    MONTHLY: {
      formatter: (d: number) => d,
      reverter: (d: number) => d,
      setter: (d: number) => d,
      unitLabel: '월급',
      priceLabel: '원',
      pricePlaceHolder: '0',
    },
  }
  return mappers
})()

/**
 * LocalStorage Form Data(임시저장) 관리
 */
export const setJobPostFormDataAtLocal = (data: JobPostType, customKey?: string) => {
  storage.setItemAtLocal(customKey ?? CREATE_JOB_POST_FORM, {
    ...data,
    images: [...data.images.filter((image) => image?.id)],
  })
}

/**
 * LocalStorage에서 JobPost 데이터 가져오기
 */
export const getJobPostFormDataFromLocal = (customKey?: string): JobPostType | null => {
  let jobPostFormData: JobPostType | null = null

  try {
    jobPostFormData = storage.getItemFromLocal<JobPostType>(customKey ?? CREATE_JOB_POST_FORM)
  } catch (err) {
    console.error(err)
    captureException(err)
  }

  return jobPostFormData
}

/**
 * 서버로 발송하는 데이터필드 from schema, schema에서 autogen이 되도록 개선 필요
 */
const JOB_POST_FORM_FIELDS = Object.keys(jobPostIninitialValues) as (keyof JobPostType)[]

/**
 * LocalStorage에서 가져온 데이터를 Form Type에 맞게 변경하기
 * */
export const handleLocalJobPostInitialValues = (data: Record<string, any>): JobPostType => {
  return pipe(
    (_data) => ({
      ...jobPostIninitialValues,
      ..._data,
      terms: false,
    }),
    transformFields<typeof data, JobPostType>({
      workplaceRequiredActions: (workplaceRequiredActions) => workplaceRequiredActions ?? null,
      workDates: (workDates) =>
        workDates
          ? workDates
              .map((date: string) => startOfDay(new Date(date)))
              .filter((date: Date) => !isPastDate(date, { unit: 'day' }))
          : [],
      isPersonal: (isPersonal) => Boolean(isPersonal),
      jobTasks: (jobTasks, { isPersonal }) =>
        jobTasks
          ? jobTasks.filter((v: AllJobTaskType) => includes(!isPersonal ? jobTaskValues : personalJobTaskValues, v))
          : [],
    }),
    pick(JOB_POST_FORM_FIELDS)
  )(data)
}

/**
 * 서버에서 가져온 데이터를 Form Type에 맞게 변경하기
 * */
export const handleJobPostInitialValues = (data: Record<string, any>): JobPostType => {
  return pipe(
    (_data) => ({
      ..._data,
      workplaceRequiredActions: null,
      terms: false,
    }),
    transformFields<typeof data, JobPostType>({
      isPersonal: (_, { companyName, isEnterprise }) => !isEnterprise && !companyName,
      workplaceLat: (_, { workplaceLocation }) => workplaceLocation?.lat || null,
      workplaceLng: (_, { workplaceLocation }) => workplaceLocation?.lng || null,
      images: (images) => images.map(({ _id, ...image }: any) => ({ ...image, id: _id })),
      isPhoneIncontactable: (_, { isPhoneContactable }) => !isPhoneContactable,
      workDates: (workDates) =>
        workDates
          ?.map((date: string) => startOfDay(new Date(date)))
          .filter((date: Date) => !isPastDate(date, { unit: 'day' })) ?? [],
      jobTasks: (jobTasks, { companyName: isNotPersonal, isEnterprise }) =>
        jobTasks
          ? jobTasks.filter((v: AllJobTaskType) =>
              includes(isEnterprise || isNotPersonal ? jobTaskValues : personalJobTaskValues, v)
            )
          : [],
    }),
    pick(JOB_POST_FORM_FIELDS)
  )(data)
}

/**
 * 서버로 발송하는 데이터필드 from schema, schema에서 autogen이 되도록 개선 필요
 */
const JOB_POST_SERVER_INPUTS: (keyof JobPostServerInputs)[] = [
  'authorType',
  'companyName',
  'workplaceAddress',
  'workplaceRoadAddress',
  'visibleDetailedWorkplaceAddress',
  'workplaceLat',
  'workplaceLng',
  'workPeriod',
  'workDays',
  'workDates',
  'workTimeStart',
  'workTimeEnd',
  'isWorkTimeNegotiable',
  'salaryType',
  'salary',
  'salaryPayMethod',
  'title',
  'content',
  'images',
  'phone',
  'isPhoneContactable',
  'eventGroup',
  'jobTasks',
  'exposureItems',
]

/**
 * Form Type을 Mutation Input Type으로 formatting
 */
export const handleJobPostFormValues = (data: JobPostType) => {
  return pipe(
    (_data) => ({
      ..._data,
      visibleDetailedWorkplaceAddress: true,
    }),
    transformFields<JobPostType, JobPostServerInputs>({
      companyName: (companyName, { isPersonal }) => (isPersonal ? null : companyName),
      phone: (phone, { isPersonal }) => (isPersonal ? null : phone || null),
      images: (images) => images.map((image) => image.id),
      workDays: (workDays, { workPeriod }) => (workPeriod === 'LESS_THAN_A_MONTH' ? [] : sortWorkDays(workDays)),
      workDates: (workDates, { workPeriod }) =>
        workPeriod === 'MORE_THAN_A_MONTH' ? [] : workDates.map((date: Date) => format(date, 'yyyy-MM-dd')),
      isPhoneContactable: (_, { isPhoneIncontactable, isPersonal }) => (isPersonal ? false : !isPhoneIncontactable),
      jobTasks: (jobTasks) => (jobTasks.length === 0 ? ['ETC' as JobTaskType] : sortJobTasks(jobTasks)),
      workTimeStart: (workTimeStart, { isWorkTimeNegotiable, isPersonal }) =>
        isPersonal && isWorkTimeNegotiable ? '09:00' : workTimeStart,
      workTimeEnd: (workTimeEnd, { isWorkTimeNegotiable, isPersonal }) =>
        isPersonal && isWorkTimeNegotiable ? '18:00' : workTimeEnd,
    }),
    pick(JOB_POST_SERVER_INPUTS)
  )(data)
}

/**
 * 서버로 발송하는 데이터필드 from schema, schema에서 autogen이 되도록 개선 필요
 */
const JOB_POST_BUSINESS_FORM_FIELDS = [
  'companyName',
  'workplaceAddress',
  'workplaceRoadAddress',
  'workplaceLat',
  'workplaceLng',
  'isPhoneIncontactable',
] as const

/**
 * LocalStorage에서 최근 작성한 사업자정보 가져오기
 */
export const getBusinessFormValuesFromLocal = (): Pick<
  JobPostType,
  (typeof JOB_POST_BUSINESS_FORM_FIELDS)[number]
> | null => {
  return storage.getItemFromLocal(BUSINESS_FORM_VALUES)
}

/**
 * LocalStorage에 최근 사업자 정보 저장하기
 */
export const setBusinessFormValuesAtLocal = (
  formValues: Pick<JobPostType, (typeof JOB_POST_BUSINESS_FORM_FIELDS)[number]>
) => {
  storage.setItemAtLocal(BUSINESS_FORM_VALUES, pick(JOB_POST_BUSINESS_FORM_FIELDS)(formValues))
}

/**
 * 서버로 발송하는 데이터필드 from schema, schema에서 autogen이 되도록 개선 필요
 */
const JOB_POST_WORK_ADDRESS_FIELDS = [
  'workplaceAddress',
  'workplaceRoadAddress',
  'workplaceLat',
  'workplaceLng',
] as const

/**
 * LocalStorage에서 최근 작성한 주소 가져오기
 */
export const getRecentlyJobPostAddressFromLocal = (): Pick<
  JobPostType,
  (typeof JOB_POST_WORK_ADDRESS_FIELDS)[number]
> | null => {
  return storage.getItemFromLocal(RECENTLY_JOB_POST_ADDRESS)
}

/**
 * LocalStorage에 최근 작성한 주소 저장하기
 */
export const setRecentlyJobPostAddressAtLocal = (
  formValues: Pick<JobPostType, (typeof JOB_POST_WORK_ADDRESS_FIELDS)[number]>
) => {
  return storage.setItemAtLocal(RECENTLY_JOB_POST_ADDRESS, pick(JOB_POST_WORK_ADDRESS_FIELDS)(formValues))
}

// ------------------------------------------------------------------------------------
// Resume Form
// ------------------------------------------------------------------------------------

/**
 * 서버로 발송하는 데이터필드 from schema, schema에서 autogen이 되도록 개선 필요
 */
const RESUME_SERVER_INPUTS: (keyof ResumeServerInputs)[] = [
  'name',
  'age',
  'phone',
  'images',
  'gender',
  'age',
  'birthYear',
  'experiences',
  'content',
  'characters',
  'extraFeatures',
  'nationality',
  'visa',
]

/**
 * Form Type을 Mutation Input Type으로 formatting
 */
export const handleResumeFormValues = (data: ResumeType) => {
  return pipe(
    transformFields<ResumeType, ResumeServerInputs>({
      name: (name) => setEmptyStringToNull(name),
      images: (images) => images.map((image: any) => image.id),
      birthYear: (birthYear) => birthYear || undefined,
      age: (age) => age || null,
      gender: (gender) => gender || null,
      visa: (visa, { isForeigner }) => (isForeigner ? visa : null),
      experiences: map(handleResumeExperienceFormValues),
    }),
    pick(RESUME_SERVER_INPUTS)
  )(data)
}

export const handleResumeExperienceFormValues = (data: ExperienceType) => {
  return { ...data, year: Number(data.year) }
}

/**
 * Enum type value에 해당 하지 않는 값 제거
 */
export const filterRunTimeEnumFields = (data: Partial<JobPostType>) => {
  return pipe(
    transformFields<Partial<JobPostType>, Partial<JobPostType>>({
      salaryType: (v) => (v && salaryTypeValues.includes(v) ? v : null),
      workPeriod: (v) => (v && workPeriodValues.includes(v) ? v : null),
      workTimeStart: (v) => (v && workTimeValues.includes(v) ? v : null),
      workTimeEnd: (v) => (v && workTimeValues.includes(v) ? v : null),
      workDays: (v) => v && v.filter((day) => workDayValues.includes(day)),
      jobTasks: (v) => v && v.filter((task) => jobTaskValues.includes(task)),
    }),
    pick(JOB_POST_FORM_FIELDS)
  )(data)
}
// ------------------------------------------------------------------------------------
// Common
// ------------------------------------------------------------------------------------

export const convertMapToOptions = (obj: object) =>
  Object.entries(obj).map(([k, v]) => ({
    value: k,
    label: v,
  }))
