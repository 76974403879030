import { isEmpty, isNotNil } from 'es-toolkit/compat'

import { visaTypeMap } from '@src/constants/form'
import {
  ApplicantAgeType,
  ApplicantGenderType,
  ApplicantNationalityType,
  ApplicationStatus,
} from '@src/types/application'
import { VisaType } from '@src/types/resume'

export const isVisaType = (value?: Nullable<string>): value is VisaType => {
  return isNotNil(value) && Object.keys(visaTypeMap).includes(value)
}

export const isNationalityType = (value?: Nullable<string>): value is ApplicantNationalityType => {
  return isNotNil(value) && Object.keys(ApplicantNationalityType).includes(value)
}

export const isAgeType = (value?: Nullable<string>): value is ApplicantAgeType => {
  return isNotNil(value) && Object.keys(ApplicantAgeType).includes(value)
}

export const isGenderType = (value?: Nullable<string>): value is ApplicantGenderType => {
  return isNotNil(value) && Object.keys(ApplicantGenderType).includes(value)
}

export const isApplicationStatusType = (value?: Nullable<string>): value is ApplicationStatus => {
  return isNotNil(value) && Object.keys(ApplicationStatus).includes(value)
}

export const isNotEmpty = <T>(value?: T) => {
  return !isEmpty(value)
}
