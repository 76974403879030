import VConsole from '@daangn/vconsole'
import { createRoot, Root } from 'react-dom/client'
import type { Environment } from 'relay-runtime'

import { IS_DEV } from '@src/config'
import { store } from '@src/graphql/graphql'
import { RelayPluginErrorBoundary } from '@src/sdks/vConsole/plugins/ErrorBoundary'
import RelayNetworkVConsoleTab from '@src/sdks/vConsole/plugins/RelayNetworkVConsoleTab'
import RelayStoreVConsoleTab from '@src/sdks/vConsole/plugins/RelayStoreVConsoleTab'

import { RelayPluginModel, relayPluginModel } from './relay.model'

const MODES = {
  NETWORK: 'network',
  STORE: 'store',
} as const

type Mode = (typeof MODES)[keyof typeof MODES]

interface TopBarItem {
  name: string
  data: {
    mode: Mode
  }
  actived?: boolean
  onClick: () => void
}

interface ToolItem {
  name: string
  global: boolean
  onClick: () => void
}

class RelayPlugin extends VConsole.VConsolePlugin {
  private model: RelayPluginModel
  private root: Root | null
  private currentMode: Mode | null
  private cleanup: (() => void) | null = null

  constructor(environment: Environment) {
    super('relay', 'Relay')
    this.root = null
    this.currentMode = IS_DEV ? MODES.NETWORK : MODES.STORE
    this.model = relayPluginModel
    this.model.setEnvironment(environment)

    this.onRenderTab = this.onRenderTab.bind(this)
    this.onAddTool = this.onAddTool.bind(this)
    this.onAddTopBar = this.onAddTopBar.bind(this)
    this.onShow = this.onShow.bind(this)

    this.on('renderTab', this.onRenderTab)
    this.on('addTool', this.onAddTool)
    this.on('addTopBar', this.onAddTopBar)
    this.on('show', this.onShow)
    this.on('showConsole', this.onShow)
  }

  destroy() {
    if (this.cleanup) {
      this.cleanup?.()
      this.root = null
    }
  }

  private onRenderTab(callback: (html: string) => void) {
    const html = `
    <style>
      .vc-group-detail {
        display: none;
        border-bottom: 1px solid var(--VC-FG-3);
      }
      .vc-group-detail.vc-actived {
        display: block;
      }
      .vc-group {
        cursor: pointer;
      }
      .vc-group:hover {
        background-color: var(--VC-BG-3);
      }
      .vc-detail-head-row {
        border-bottom: 1px solid var(--VC-FG-3);
        padding: 0.5rem;
      }
      .vc-detail-head-row:first-child {
        border-top: none;
      }
      .vc-detail-body {
        white-space: pre-wrap;
        word-break: break-all;
        background: #212124;
        color: #f7f8fa;
        line-height: 1.5;
        padding: 0.5rem;
      }
      .vc-record-name {
        white-space: pre-wrap;
        word-break: break-all;
        line-height: 1.5;
        padding: 0.3rem;
        border-top: 1px solid var(--VC-FG-3);
      }
      .vc-record-name:first-child {
        border-top: none;
      }
      .vc-record-detail {
        border-top: none;
        white-space: pre-wrap;
        word-break: break-all;
        background: #212124;
        color: #f7f8fa;
        line-height: 1.5;
        padding: 0.5rem;
      }
      .flex {
        display: flex;
        justify-content: space-between;
      }
      .bold {
        font-weight: bold;
      }
      .light {
        color: var(--VC-FG-1);
      }
    </style>
    <div class="my-tab-content">
        <div id="content-area">
            <!-- 탭 내용이 들어갈 영역 -->
        </div>
    </div>
`
    return callback(html)
  }

  private onShow() {
    const area = document.getElementById('content-area')
    if (!area) return
    if (!this.root) {
      this.root = createRoot(area)
    }

    this.root.render(
      <RelayPluginErrorBoundary>
        {this.currentMode === MODES.NETWORK ? (
          <RelayNetworkVConsoleTab requests={this.model.getRequests()} />
        ) : (
          <RelayStoreVConsoleTab source={this.getGroupedSource()} />
        )}
      </RelayPluginErrorBoundary>
    )

    const handleItemClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      const tabId = target.dataset.tab
      if (!tabId) return

      const tabGroup = target.closest('.vc-tabs')
      if (!tabGroup) return

      tabGroup.querySelectorAll('.vc-tab-item').forEach((t) => {
        t.classList.remove('vc-actived')
      })
      target.classList.add('vc-actived')

      tabGroup.querySelectorAll('.vc-tab-panel').forEach((p) => {
        p.classList.remove('vc-actived')
      })
      document.getElementById(tabId)?.classList.add('vc-actived')
    }

    setTimeout(() => {
      const tabs = document.querySelectorAll('.vc-tab-item') as unknown as HTMLElement[]
      tabs.forEach((tab) => tab.addEventListener('click', handleItemClick))

      return () => {
        tabs.forEach((tab) => tab.removeEventListener('click', handleItemClick))
      }
    }, 0)
  }

  private onAddTopBar(callback: (list: TopBarItem[]) => void) {
    const topBarList = [
      ...(IS_DEV
        ? [
            {
              name: MODES.NETWORK,
              data: {
                mode: MODES.NETWORK,
              },
              actived: this.currentMode === MODES.NETWORK,
              onClick: () => {
                this.currentMode = MODES.NETWORK
                this.onShow()
              },
            },
          ]
        : []),
      {
        name: MODES.STORE,
        data: {
          mode: MODES.STORE,
        },
        actived: this.currentMode === MODES.STORE,
        onClick: () => {
          this.currentMode = MODES.STORE
          this.onShow()
        },
      },
    ]

    callback(topBarList)
  }

  private onAddTool(callback: (list: ToolItem[]) => void) {
    const toolList = [
      {
        name: 'Clear',
        global: false,
        onClick: () => {
          this.model.clearRequests()
          this.onShow()
        },
      },
    ]
    callback(toolList)
  }

  private getGroupedSource() {
    const source = store.getSource()
    if (!source) return {}

    return source.getRecordIDs().reduce(
      (acc, id) => {
        const record = source.get(id)
        if (!record) return acc

        const typename = record.__typename as string
        if (!typename) return acc

        acc[typename] = acc[typename] || []
        acc[typename].push(record)
        return acc
      },
      {} as Record<string, any[]>
    )
  }
}

export default RelayPlugin
