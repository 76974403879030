import authPlugin from '@daangn/plantae-plugin-auth'
import commonHeadersPlugin from '@daangn/plantae-plugin-common-headers'
import type { AxiosInstance } from 'axios'

import { AUTH_TOKEN, IS_DEV_NATIVE, IS_NATIVE } from '@src/config'
import { karrotBridgeV2 } from '@src/sdks/karrotBridge'

type PluginName = 'commonHeaders' | 'auth'

interface PlantaeConfigOptions {
  enabledPlugins?: PluginName[]
}

interface PlantaeConfigParams<T extends typeof fetch | AxiosInstance | undefined> {
  client: T
  options?: PlantaeConfigOptions
}

type Plugin = ReturnType<typeof authPlugin> | ReturnType<typeof commonHeadersPlugin>

export const getPlantaeConfig = <T extends typeof fetch | AxiosInstance | undefined>({
  client,
  options,
}: PlantaeConfigParams<T>) => {
  const _options = {
    enabledPlugins: options?.enabledPlugins ?? ['commonHeaders', 'auth'],
  }

  const getPlugins = (): Plugin[] => {
    if (IS_DEV_NATIVE) return []

    return (_options.enabledPlugins ?? []).reduce<Plugin[]>((acc, pluginName) => {
      const plugin = (() => {
        switch (pluginName) {
          case 'commonHeaders':
            return commonHeadersPlugin({
              bridge: karrotBridgeV2,
            })
          case 'auth':
            return authPlugin({
              bridge: karrotBridgeV2,
              options: {
                fallbackAuthToken: IS_NATIVE || {
                  value: AUTH_TOKEN,
                },
              },
            })
          default:
            return undefined
        }
      })()

      return plugin ? [...acc, plugin] : acc
    }, [])
  }

  return {
    client,
    plugins: getPlugins(),
  }
}
