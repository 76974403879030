import { compact } from 'lodash'
import { createFetch } from 'plantae'
import { CacheConfig, FetchFunction, Network, RequestParameters, Variables } from 'relay-runtime'

import { getPlantaeConfig } from '@src/sdks/plantae'
import { Middleware, MiddlewareNextFn, RelayRequest, RelayResponse } from '@src/types/relay'

interface NetworkProps {
  middlewares?: Array<Middleware | null>
}

// authToken 관련 대응 https://www.notion.so/daangn/Access-Token-a64e927cef314b9484a4cb7cf36dbd9a
const fetchWithAuth = createFetch(getPlantaeConfig({ client: undefined }))

const fetchQuery = (
  operation: RequestParameters,
  variables: Variables,
  headers: Record<string, string>,
  url?: string
) => {
  return fetchWithAuth(url ? url : '/graphql', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      ...headers,
    },
    body: JSON.stringify({
      id: operation.id,
      query: operation.text ?? '',
      variables,
    }),
  })
}

const genId = (() => {
  let _id = 0
  return () => String(++_id)
})()

function applyMiddlewares(middlewares: Middleware[]): FetchFunction {
  const fetchFn = (operation: RequestParameters, variables: Variables, cacheConfig: CacheConfig) => {
    const requestId = operation.id || operation.name || genId()
    const req = { operation, variables, cacheConfig, headers: {}, url: undefined, id: requestId }
    const initialMiddlewareNextFn: MiddlewareNextFn = async (req) => {
      const resFromFetch = await fetchQuery(req.operation, req.variables, req.headers, req.url)
      const response = await createResponseFromFetch(resFromFetch)

      if (
        !response ||
        response.errors ||
        !response.data ||
        (response.status && (response.status < 200 || response.status >= 300))
      ) {
        throw createRequestError(req, response)
      }

      return { data: response.data }
    }

    return middlewares.reduceRight((next, middleware) => {
      return middleware(next)
    }, initialMiddlewareNextFn)(req)
  }

  return fetchFn
}

export function createCustomNetwork({ middlewares = [] }: NetworkProps) {
  const notNullMidlewares = compact(middlewares)
  const fetchFunctionWithMiddleware = applyMiddlewares(notNullMidlewares)
  return Network.create(fetchFunctionWithMiddleware)
}

async function createResponseFromFetch(resFromFetch: Response): Promise<RelayResponse> {
  const res: RelayResponse = {
    status: resFromFetch.status,
  }

  if (res.status < 200 || res.status >= 300) {
    res.text = await resFromFetch.text()
  } else {
    res.json = await resFromFetch.json()
    if (res.json.data) {
      res.data = res.json.data
    }
    if (res.json.errors) {
      res.errors = res.json.errors
    }
  }

  return res
}

const createRequestError = (req: RelayRequest, res?: RelayResponse) => {
  let errorReason = ''

  if (!res) {
    errorReason = 'Server return empty response.'
  } else if (res?.errors) {
    errorReason = JSON.stringify(res.errors)
  } else if (!res.json) {
    errorReason =
      (res.text ? res.text : `Server return empty response with Status Code: ${res.status}.`) +
      (res ? `\n\n${res.toString()}` : '')
  } else if (!res.data) {
    errorReason = 'Server return empty response.data.\n\n' + res.toString()
  }

  const error = new RequestError(
    `Relay request for \`${req.id}\` failed by the following reasons:\n\n${errorReason}`,
    req,
    res
  )

  return error
}

export class RequestError extends Error {
  req: RelayRequest
  res?: RelayResponse

  constructor(msg: string, req: RelayRequest, res?: RelayResponse) {
    super(msg)
    this.name = 'RelayRequestError'
    this.req = req
    this.res = res
  }
}
