import { addHours, isAfter } from 'date-fns/esm'
import { isEmpty } from 'es-toolkit/compat'
import { graphql } from 'relay-runtime'

import { BannersWhereInput, MainPageFeedQuery$variables } from '@src/__generated__/MainPageFeedQuery.graphql'
import storage from '@src/api/storage'
import { RESET_FILTERS } from '@src/constants/action'
import { FEED_FILTER_STATE } from '@src/constants/api'
import { DEFAULT_FILTER_SET } from '@src/constants/filter'
import { jobTaskValues } from '@src/constants/form'
import { FilterState } from '@src/types/filter'
import { JobTaskType, RegionRange, WorkDay, WorkPeriod, WorkTime } from '@src/types/jobPost'
import { checkWorkTimeValid } from '@src/utils/string'

export const MainPageFeedQueryNode = graphql`
  query MainPageFeedQuery(
    $where: MainFeedWhereInput!
    $filter: MainFeedFilterInput
    $regionWhere: RegionWhereInput!
    $neighborsWhere: RegionNeighborsWhereInput!
    $bannersWhere: BannersWhereInput!
  ) {
    me {
      _id
      __typename
      jobPosts(filter: { status: [ACCEPTED, PENDING] }, first: 2) {
        edges {
          node {
            advertisements {
              status
              exposureType
            }
            ...JobPostCard_jobPost
          }
        }
      }
    }
    ...MainJobPosts_query @arguments(where: $where, filter: $filter)
    ...MainNewJobPostFilterSubscriptionCallout_query
    ...MainFilterArea_query @arguments(regionWhere: $regionWhere, neighborsWhere: $neighborsWhere)
    ...MainBannerSection_query @arguments(bannersWhere: $bannersWhere)
  }
`

export const MainPageFeedQueryVariables = ({
  regionId,
  filterState,
  bannersWhere,
}: {
  regionId: number
  filterState: FilterState
  bannersWhere: Omit<BannersWhereInput, 'section'>
}): MainPageFeedQuery$variables => {
  return {
    where: {
      region: regionId,
      regionRange: filterState.regionRange,
    },
    regionWhere: {
      _id: regionId,
    },
    neighborsWhere: {
      range: filterState.regionRange,
      depth: 'DEPTH_3',
    },
    filter: {
      workPeriod: filterState.workPeriod,
      workDaysFilterType: isEmpty(filterState.workDays) ? undefined : 'INCLUDE_STRICT',
      workDays: filterState.workPeriod === 'LESS_THAN_A_MONTH' ? DEFAULT_FILTER_SET.workDays : filterState.workDays,
      workTime: filterState.workTime,
      jobTasks: filterState.jobTasks,
    },
    bannersWhere: {
      section: 'MAIN',
      ...bannersWhere,
    },
  }
}

export function MainPageFeedQueryLocalVariables() {
  const localData = storage.getItemFromLocal<{
    state: FilterState
    date: Date
  }>(FEED_FILTER_STATE)
  if (isEmpty(localData)) return {}

  if (isAfter(new Date(), addHours(new Date(localData.date), 3))) {
    storage.removeItemAtLocal(FEED_FILTER_STATE)
    return {}
  }

  const isWorkTimeValid =
    localData.state.workTime &&
    checkWorkTimeValid({
      start: localData.state.workTime.start as WorkTime,
      end: localData.state.workTime.end as WorkTime,
    })

  const newState: FilterState = {
    ...localData.state,
    // Deprecated jobTasks 필터링
    ...(localData.state.jobTasks
      ? { jobTasks: localData.state.jobTasks.filter((v) => jobTaskValues.includes(v)) }
      : {}),
    ...(isWorkTimeValid ? {} : { workTime: undefined }),
  }
  storage.setItemAtLocal(FEED_FILTER_STATE, { state: newState, date: new Date() })
  return newState
}

export function MainPageFeedQueryPathToFilterState(path: string): FilterState {
  const u = new URL(path, 'file://')

  const regionRange = u.searchParams.get('regionRange') as RegionRange | null
  const workPeriod = u.searchParams.get('workPeriod') as WorkPeriod | null
  const workDays = (u.searchParams.get('workDays') ?? '').split(',').filter((v) => v !== '') as WorkDay[]
  const resetFilter = (u.searchParams.get('actions') ?? '').split(',').includes(RESET_FILTERS)
  const jobTasks = (u.searchParams.get('jobTasks') ?? '').split(',').filter((v) => v !== '') as JobTaskType[]

  if (resetFilter) {
    return DEFAULT_FILTER_SET
  }

  return {
    regionRange: regionRange ?? RegionRange.RANGE_3,
    workPeriod: workPeriod ?? undefined,
    jobTasks: jobTasks ?? [],
    workDays,
    workTime: undefined,
    filterStatus: {
      any: false,
      region: false,
      workPeriod: false,
      jobTasks: false,
      workDays: false,
      workTime: false,
    },
  }
}
