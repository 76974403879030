import { compact } from 'es-toolkit/compat'
import React from 'react'
import { match } from 'ts-pattern'

import { timeAgo } from '@src/utils/date'

interface Props {
  source: Record<string, any>
}

const SEARCHABLE_TYPES = [
  'Advertisement',
  'Application',
  'Author',
  'Banner',
  'BusinessLicense',
  'BusinessRegistration',
  'Count',
  'Enterprise',
  'EnterpriseTempalte',
  'ExperimentSegment',
  'Image',
  'InviteEvent',
  'JobPost',
  'JobReview',
  'NewJobPostSubscription',
  'Payment',
  'Permalink',
  'Promotion',
  'Region',
  'Statistic',
  'Subway',
  'User',
  'UserExtend',
  'Watch',
]

const RelayStoreVConsoleTab: React.FC<Props> = ({ source }) => {
  const [openState, setOpenState] = React.useState({} as { [key: string]: boolean })

  const recordTitle = (record: any) => {
    const mapBusinessType = (buisnessType: string) => {
      if (!buisnessType) return ''

      switch (buisnessType) {
        case 'SMALL_BUSINESS':
          return '소상공인'
        case 'PERSONAL':
          return '이웃알바'
        case 'ENTERPRISE':
          return '엔터프라이즈'
        case 'LARGE_SCALE_BUSINESS':
          return '유카테'
        default:
          return buisnessType
      }
    }

    return match(record)
      .with({ __typename: 'JobPost' }, () => {
        const title = record.title
        const buisnessType = mapBusinessType(record.buisnessType)
        const ago = record.createdAt ? `${timeAgo({ date: record.createdAt })} 작성` : ''
        return compact([title, buisnessType, ago]).join(' | ') || record.__id
      })
      .with({ __typename: 'Banner' }, () => {
        const title = record.title
        const eventKind = record.eventKind
        return compact([title, eventKind]).join(' | ') || record.__id
      })
      .with({ __typename: 'EventGroup' }, () => {
        if (record.__id.startsWith('client:root')) return record.__id
        const type = record.type
        const name = record.name
        return compact([type, name]).join(' | ') || record.__id
      })
      .with({ __typename: 'Region' }, () => {
        const name = record.name || record.name3
        const id = record._id || record.__id
        return compact([name, id]).join(' | ')
      })
      .with({ __typename: 'User' }, () => {
        const name = record.nickname || record.name
        const id = record._id || record.__id
        const role = match(record.role)
          .with('AUTHOR_APPLICANT', () => '구인자 & 지원자')
          .with('AUTHOR', () => '구인자')
          .with('APPLICANT', () => '지원자')
          .otherwise(() => '신규')
        return compact([id, name, role]).join(' | ')
      })
      .with({ __typename: 'Resume' }, () => {
        const name = record.name
        const id = record._id || record.__id
        return compact([name, id]).join(' | ')
      })
      .otherwise(() => record.__id)
  }

  return Object.entries(source)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([typename, sources], index) => {
      const foldId = `fold-${index}`
      const handleFoldClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        setOpenState((prev) => {
          return {
            ...prev,
            [foldId]: !prev[foldId],
          }
        })
      }

      const isSearchable = SEARCHABLE_TYPES.includes(typename)

      const handleResolverClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (!isSearchable) return

        const url = `https://github.com/daangn/jobs/tree/master/src/graphql/schema/${typename}/${typename}.ts`
        window.open(url)
      }

      return (
        <div key={index} className="vc-table" onClick={handleFoldClick}>
          <div className="vc-table-row vc-group">
            <div className="vc-table-col vc-table-col-8 bold flex">
              <div>{typename}</div>
              {isSearchable && (
                <div style={{ paddingRight: '0.5rem', cursor: 'pointer' }} onClick={handleResolverClick}>
                  🔎
                </div>
              )}
            </div>
          </div>
          <div className="vc-group-detail " style={openState[foldId] ? { display: 'block' } : { display: 'none' }}>
            {(sources as any[]).map((el, idx) => {
              const id = `fold-${index}-${idx}`
              const handleRecordClick = (e: React.MouseEvent) => {
                e.stopPropagation()
                setOpenState((prev) => {
                  return {
                    ...prev,
                    [id]: !prev[id],
                  }
                })
              }

              return (
                <React.Fragment key={idx}>
                  <div className="vc-record-name vc-group" onClick={handleRecordClick}>
                    {recordTitle(el)}
                  </div>
                  <div
                    className="vc-group-detail vc-record-detail"
                    style={openState[id] ? { display: 'block' } : { display: 'none' }}>
                    {JSON.stringify(el, null, 2)}
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      )
    })
}

export default RelayStoreVConsoleTab
