export enum ApplicationStatus {
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
  HIRED = 'HIRED',
  REJECTED = 'REJECTED',
}

export enum ApplicantGenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NONE = 'NONE',
}

export enum ApplicantNationalityType {
  DOMESTIC = 'DOMESTIC',
  FOREIGNER = 'FOREIGNER',
  UNKNOWN = 'UNKNOWN',
}

export enum ApplicantAgeType {
  TEENAGER = 'TEENAGER',
  TWENTY = 'TWENTY',
  THIRTY = 'THIRTY',
  FORTY = 'FORTY',
  FIFTY_OR_OLDER = 'FIFTY_OR_OLDER',
}
