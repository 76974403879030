/* eslint-disable import/no-duplicates */
import {
  add,
  differenceInCalendarDays,
  endOfDay,
  format,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  getDay,
  isPast,
  parse,
  startOfDay,
} from 'date-fns'
import ko from 'date-fns/locale/ko'
import { format as tzFormat } from 'date-fns-tz'

import { CURRENT_YEAR } from '@src/constants/date'

import { getHolidays } from './holiday'

export const MS = {
  second: 1000,
  minute: 60000,
  hour: 3600000,
  day: 86400000,
  week: 604800000,
  month: 2592000000,
  year: 31536000000,
} as const

export const calcRoundedPassedDate = (targetDate: Dateable): string => {
  // 일 단위 반올림하여 개월 수 차이를 가져오기 위해, date-fns 내장함수인 formatDistanceToNowStrict 사용
  const formatedMonthDiff = formatDistanceToNowStrict(toDate(targetDate), {
    locale: ko,
    unit: 'month',
  })
  const diffAmount = Number(formatedMonthDiff.match(/\d+/)?.[0])

  const monthsDiff = diffAmount % 12
  const yearsDiff = Math.floor(diffAmount / 12)

  if (yearsDiff < 1 && monthsDiff < 1) {
    return calcPassedDate(targetDate)
  } else if (yearsDiff < 1 && monthsDiff <= 12) {
    return `${monthsDiff}개월`
  } else if (monthsDiff <= 5) {
    return `${yearsDiff}년`
  } else {
    return `${yearsDiff + 1}년`
  }
}

export const calcPassedDate = (targetDate: Dateable): string => {
  return formatDistanceToNow(toDate(targetDate), { locale: ko, includeSeconds: true }).replace(
    /(약\s*)|(\s*미만$)/g,
    ''
  )
}

export const calcDistanceWithDateAndHour = (targetDate: Dateable): string => {
  const dday = toDate(targetDate).getTime()
  const today = new Date().getTime()
  const gap = dday - today
  const day = Math.floor(gap / (1000 * 60 * 60 * 24))
  const hour = Math.ceil((gap % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const adjustedDay = hour === 24 ? day + 1 : day

  return `${adjustedDay > 0 ? `${adjustedDay}일` : ''}${
    hour > 0 && hour < 24 ? `${adjustedDay > 0 ? ' ' : ''}${hour}시간` : ''
  }`
}

// 비교 단위에 따라, 과거 여부를 판단, 단위는 정확한 시간, 일, 주, 월, 년 등
export const isPastDate = (
  targetDate: Dateable,
  options: { unit: 'time' | 'day' | 'week' | 'month' | 'year' } = { unit: 'time' }
): boolean => {
  const now = new Date()
  const target = toDate(targetDate)

  switch (options.unit) {
    case 'time':
      return target.getTime() < now.getTime()

    case 'day': {
      const targetTime = startOfDay(target).getTime()
      const nowTime = startOfDay(now).getTime()
      const dayDiff = Math.floor((targetTime - nowTime) / MS.day)
      return dayDiff < 0
    }

    case 'week': {
      const targetTime = startOfDay(target).getTime()
      const nowTime = startOfDay(now).getTime()
      const weekDiff = Math.floor((targetTime - nowTime) / MS.week)
      return weekDiff < 0
    }

    case 'month': {
      const targetTime = startOfDay(target).getTime()
      const nowTime = startOfDay(now).getTime()
      const monthDiff = Math.floor((targetTime - nowTime) / MS.month)
      return monthDiff < 0
    }

    case 'year':
      return target.getFullYear() < now.getFullYear()

    default:
      return isPast(target)
  }
}

export const areDateEqual = (dateA: Dateable, dateB: Dateable): boolean => {
  const [a, b] = [toDate(dateA), toDate(dateB)]
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate()
}

export const isPastDateAddDaysLimit = ({
  targetDate,
  days,
}: {
  targetDate?: Dateable | null
  days: number
}): boolean => {
  if (!targetDate) return false
  return add(toDate(targetDate), { days }).getTime() < new Date().getTime()
}

export const isWithinDays = ({ targetDate, days }: { targetDate?: Dateable | null; days: number }): boolean => {
  if (!targetDate) return false

  return differenceInCalendarDays(toDate(targetDate), new Date()) <= days
}

export const formatDate = (targetDate: Dateable, type = 'PPP', options = { locale: ko }) => {
  return format(toDate(targetDate), type, options)
}

export const formatDateDot = (targetDate: Dateable) => {
  return formatDate(targetDate, 'yyyy.MM.dd')
}

// Todo: localize 대응 필요
export const setEndOfDay = (targetDate: Dateable) => {
  return endOfDay(toDate(targetDate))
}

export const localeDateString = (targetDate: Dateable, type = 'PPP', options = { timeZone: 'KST', locale: ko }) => {
  return tzFormat(toDate(targetDate), type, options)
}

export const parseDate = (date: string, format: string) => {
  return parse(date, format, new Date())
}

export const toDate = (date: string | number | Date) => {
  return date instanceof Date ? date : new Date(date)
}

export const isHolidayDate = (date: Dateable) => {
  const holidays = getHolidays()
  const _date = toDate(date)

  // 2100년 이상에 대해, holidays === '2099년까지의 공휴일만 계산할 수 있어요.'
  if (!holidays || typeof holidays === 'string') {
    return false
  }

  return getDay(_date) === 0 || holidays.some((holiday) => holiday.date.getTime() === _date.getTime())
}

export const inDates = (date: Dateable, dates: Dateable[]) => {
  return dates.some((d) => areDateEqual(d, date))
}

export const withoutDates = (dates: Dateable[], ...withoutDates: Dateable[]) => {
  return dates.filter((d) => !inDates(d, withoutDates))
}

export const sortDates = (dates: Dateable[]) => {
  return [...dates.map(toDate)].sort((a, b) => a.getTime() - b.getTime()).map(toDate)
}

export const isContinualDates = (dates: Dateable[]) => {
  if (dates.length <= 1) return true

  const sortedDates = sortDates(dates)
  const differenceDays = Math.abs(differenceInCalendarDays(sortedDates[0], sortedDates[sortedDates.length - 1]))
  return differenceDays === dates.length - 1
}

export const uniqueDates = (dates: Dateable[]) => {
  return [...new Set(dates.map((d) => toDate(d).getTime()))].map(toDate)
}

export const isBetweenDates = (date: Dateable, startDateable: Dateable, endDateable: Dateable) => {
  const [startDate, endDate] = [toDate(startDateable), toDate(endDateable)].sort((a, b) => a.getTime() - b.getTime())
  return toDate(date).getTime() >= startDate.getTime() && toDate(date).getTime() <= endDate.getTime()
}

export const datesBetweenDates = (startDate: Dateable, endDate: Dateable) => {
  const dates = []
  const [start, end] = [startOfDay(toDate(startDate)), startOfDay(toDate(endDate))].sort(
    (a, b) => a.getTime() - b.getTime()
  )
  let current = start

  while (current <= end) {
    dates.push(current)
    current = new Date(current.getTime() + MS.day)
  }

  return dates
}

export const getDiffMinutes = (dateA: Dateable, dateB: Dateable) => {
  return Math.floor(Math.abs(toDate(dateA).getTime() - toDate(dateB).getTime()) / MS.minute)
}

export const getDiffHours = (dateA: Dateable, dateB: Dateable) => {
  return Math.floor(Math.abs(toDate(dateA).getTime() - toDate(dateB).getTime()) / MS.hour)
}

export const getDiffDates = (dateA: Dateable, dateB: Dateable) => {
  return Math.abs(differenceInCalendarDays(toDate(dateA), toDate(dateB)))
}

export const getPassedMinutes = (date: Dateable) => {
  return getDiffMinutes(Date.now(), date)
}

export const getYearMonthDay = (date: Dateable) => {
  const _date = toDate(date)
  return { year: _date.getFullYear(), month: _date.getMonth() + 1, day: _date.getDate() }
}

export const getDiffDayHourMinute = (dateA: Dateable, dateB: Dateable) => {
  const [a, b] = [toDate(dateA), toDate(dateB)]
  const gap = Math.abs(a.getTime() - b.getTime())
  const day = Math.floor(gap / MS.day)
  const hour = Math.floor((gap % MS.day) / MS.hour)
  const minute = Math.floor((gap % MS.hour) / MS.minute)

  return { day, hour, minute }
}

export const getCeilMonthDiff = (dateA: Dateable, dateB: Dateable) => {
  const diff = toDate(dateA).getTime() - toDate(dateB).getTime()
  const ceilMonthDiff = Math.abs(Math.ceil(diff / MS.month))

  return ceilMonthDiff
}

export const getDateList = (daysOfMonth: number) => {
  const dateList = Array(daysOfMonth)
    .fill(0)
    .map((_, idx) => idx + 1)

  return dateList
}

export const getMonthList = () => {
  const monthList = Array(12)
    .fill(0)
    .map((_, idx) => idx + 1)

  return monthList
}

export const getYearRange = (min: number, max = CURRENT_YEAR) => {
  const count = max - min + 1
  return [
    ...Array(count)
      .fill(max)
      .map((_, delta) => max - delta),
  ].reverse()
}

export const getStartOfDayWithAddDays = ({ date = new Date(), addDays }: { date?: Dateable; addDays: number }) => {
  return startOfDay(add(toDate(date), { days: addDays }))
}

export const getMinutesFromNow = (date?: Dateable) => {
  if (!date) return 0

  return Math.floor((new Date().getTime() - toDate(date).getTime()) / MS.minute)
}

const units = [
  { key: 'year', label: '년', value: MS.year },
  { key: 'month', label: '달', value: MS.month },
  { key: 'week', label: '주', value: MS.week },
  { key: 'day', label: '일', value: MS.day },
  { key: 'hour', label: '시간', value: MS.hour },
  { key: 'minute', label: '분', value: MS.minute },
  { key: 'second', label: '초', value: MS.second },
] as const

interface RelativeTimeUtilParams {
  date: Dateable
  minUnit?: (typeof units)[number]['key']
  maxUnitCount?: number
}

export const fromNow = ({ date, minUnit = 'second', maxUnitCount = 1 }: RelativeTimeUtilParams) => {
  const targetDate = new Date(date).getTime()
  const now = new Date().getTime()

  const getTimeDiffArray = () => {
    let diff = Math.max(Math.abs(now - targetDate), MS.second)

    return units.reduce((acc, { label, value }) => {
      const unitAmount = Math.floor(diff / value)

      acc.push({
        label,
        unitAmount,
      })

      diff %= value

      return acc
    }, new Array<{ label: string; unitAmount: number }>())
  }

  const timeDiffArray = getTimeDiffArray()
  const minUnitIndex = units.findIndex((unit) => unit.key === minUnit) ?? units.length - 1

  return timeDiffArray
    .slice(0, minUnitIndex + 1)
    .filter((v) => v.unitAmount)
    .map((v) => `${v.unitAmount}${v.label}`)
    .slice(0, maxUnitCount)
    .join(' ')
}

export const timeAgo = (params: RelativeTimeUtilParams) => {
  return `${fromNow(params)} 전`
}

export const timeAhead = (params: RelativeTimeUtilParams) => {
  return `${fromNow(params)} 후`
}
