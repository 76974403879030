import { IS_ALPHA } from '@src/config'
import { createEnvironment } from '@src/graphql/graphql'
import { captureException } from '@src/sdks/sentry'
import { initVConsole } from '@src/sdks/vConsole/vConsole'

import { preload } from './preload'

async function main() {
  const relayEnvironment = createEnvironment()

  IS_ALPHA && initVConsole({ relayEnvironment })

  try {
    preload({
      path: window.location.pathname,
      search: window.location.search,
      relayEnvironment,
    })

    const { render } = await import('./render')
    render({
      relayEnvironment,
    })
  } catch (err) {
    console.error(err)
    captureException(err)
  }
}

main()
