import { Environment } from 'relay-runtime'

import RelayPlugin from './plugins/RelayPlugin'

let vconsole: any

export async function initVConsole({ relayEnvironment }: { relayEnvironment: Environment }) {
  if (vconsole) return
  const { default: VConsole } = await import('@daangn/vconsole')
  vconsole = new VConsole({
    pluginOrder: ['relay', 'default'],
  })

  addRelayPlugin(relayEnvironment)
}

export async function destroyVConsole() {
  await vconsole?.destroy()
}

async function addRelayPlugin(relayEnvironment: Environment) {
  const relayPlugin = new RelayPlugin(relayEnvironment)
  vconsole.addPlugin(relayPlugin)
}
