import { isEqual } from 'lodash'
import { compact, flow, uniq } from 'lodash/fp'

import { experiencePeriodMap, jobTasksMap } from '@src/constants/form'
import { REGEX_NON_NUMBER } from '@src/constants/regex'
import { ApplicantAgeType, ApplicantNationalityType } from '@src/types/application'
import { JobTaskType, SalaryType, WorkDay, WorkPeriod, WorkTime } from '@src/types/jobPost'
import { AgeType, ExperiencePeriod, ExperienceType, GenderType } from '@src/types/resume'

export const workPeriodMap: Record<Exclude<WorkPeriod, '%future added value'>, string> = {
  LESS_THAN_A_MONTH: '단기',
  MORE_THAN_A_MONTH: '1개월 이상',
}

const workDayMap = {
  SUN: '일',
  MON: '월',
  TUE: '화',
  WED: '수',
  THU: '목',
  FRI: '금',
  SAT: '토',
}

export const mapWorkDay = (workDay: any) => {
  return workDayMap[typeof workDay === 'number' ? mapWorkDayWithDayIndex(workDay) : (workDay as WorkDay)]
}

export const mapWorkDayWithDayIndex = (i: number) => {
  return Object.keys(workDayMap)[i] as WorkDay
}

const workDayIndexMap = {
  SUN: 6,
  MON: 0,
  TUE: 1,
  WED: 2,
  THU: 3,
  FRI: 4,
  SAT: 5,
} as const

const workDaySort = (a: WorkDay, b: WorkDay) => {
  return workDayIndexMap[a] < workDayIndexMap[b] ? -1 : 1
}

export const sortWorkDays = (workDays: readonly any[]) => {
  return workDays.slice().sort(workDaySort)
}

export const sortExperiences = <T extends ExperienceType>(experiences: T[]): T[] => {
  const periodSortWeights = {
    LESS_THAN_A_MONTH: 1,
    LESS_THAN_THREE_MONTH: 2,
    LESS_THAN_SIX_MONTH: 3,
    MORE_THAN_SIX_MONTH_LESS_THAN_ONE_YEAR: 4,
    MORE_THAN_ONE_YEAR_LESS_THAN_TWO_YEAR: 5,
    MORE_THAN_TWO_YEAR_LESS_THAN_THREE_YEAR: 6,
    MORE_THAN_THREE_YEAR: 7,
    '%future added value': 8,
  }

  return experiences.sort((a, b) => {
    if (!a.period || !b.period || a.year !== b.year) {
      return Number(b.year) - Number(a.year)
    }

    return (
      (periodSortWeights[b.period] ?? periodSortWeights['%future added value']) -
      (periodSortWeights[a.period] ?? periodSortWeights['%future added value'])
    )
  })
}

const localeWorkDayIndexMap: { [key: string]: number } = { 월: 0, 화: 1, 수: 2, 목: 3, 금: 4, 토: 5, 일: 6 }

export const cleanWorkDayString = (days: string[]) => {
  let isBreak = false

  if (days.length < 3) return days.join(',')
  for (let i = 0; i < days.length - 1; i++) {
    if (localeWorkDayIndexMap[days[i]] + 1 !== localeWorkDayIndexMap[days[i + 1]]) {
      isBreak = true
      break
    }
  }
  return isBreak ? days.join(',') : days[0] + '~' + days[days.length - 1]
}

const salaryTypeMap = {
  MONTHLY: '월급',
  DAILY: '일급',
  HOURLY: '시급',
  PER_CASE: '건당',
}

export const mapSalaryType = (salaryType: any) => {
  return salaryTypeMap[salaryType as SalaryType]
}

const genderTypeMap = {
  MALE: '남성',
  FEMALE: '여성',
  NONE: '공개하지 않음',
}

export const mapGenderType = (genderType: GenderType): string => {
  return genderTypeMap[genderType as GenderType]
}

const nationalityTypeMap = {
  DOMESTIC: '내국인',
  FOREIGNER: '외국인',
  UNKNOWN: '미정',
}

export const mapNationalityType = (nationalityType: ApplicantNationalityType): string => {
  return nationalityTypeMap[nationalityType as ApplicantNationalityType]
}

const ageTypeMap = {
  TEENAGER: '10대',
  TWENTY: '20대',
  THIRTY: '30대',
  FORTY: '40대',
  FIFTY_OR_OLDER: '50대 이상',
}

export const mapAgeType = (ageType: ApplicantAgeType): string => {
  return ageTypeMap[ageType as AgeType]
}

export const mapAgeTypeFromBirthYear = (birthYear: number) => {
  const age = new Date().getFullYear() - birthYear - 1

  if (age < 20) return ageTypeMap.TEENAGER
  if (age < 30) return ageTypeMap.TWENTY
  if (age < 40) return ageTypeMap.THIRTY
  if (age < 50) return ageTypeMap.FORTY
  return ageTypeMap.FIFTY_OR_OLDER
}

export const removeNonNumber = (str: string) => {
  return str.replace(REGEX_NON_NUMBER, '')
}

export const formatPhoneNumber = (str: string) => {
  const handledNumber = removeNonNumber(str)
  const formattedPhoneNumber = handledNumber.split('').reduce((acc, val, idx) => {
    const suffix = ' - '
    return acc + val + (idx === 2 || idx == 6 ? suffix : '')
  }, '')

  return formattedPhoneNumber
}

export const replaceStrToBirth = (value?: string) => {
  const numValue = Number(value)

  if (!value || !numValue) return null

  return Number(value.substring(0, 4))
}

export const setEmptyStringToNull = (str: any) => {
  if (typeof str === 'string' && str.length === 0) {
    return null
  }

  return str
}

export const checkWorkTimeValid = ({ start, end }: { start: WorkTime; end: WorkTime }) => {
  const _start = start.split(':').join('')
  const _endVal = end.split(':').join('')
  const _end = _endVal === '0000' ? '2400' : _endVal

  return _start < _end
}

export const getMiddleDotStr = () => {
  return String.fromCharCode(0x00b7)
}

export const getBlackSmallCircle = () => {
  return String.fromCharCode(0x2022)
}

const jobTasksIndexMap = Object.keys(jobTasksMap).reduce(
  (acc, cur, i) => {
    return { ...acc, [cur]: i }
  },
  {} as Record<JobTaskType, number>
)

const jobTasksSort = (a: JobTaskType, b: JobTaskType) => {
  return jobTasksIndexMap[a] < jobTasksIndexMap[b] ? -1 : 1
}

export const sortJobTasks = (jobTasks: readonly JobTaskType[]) => {
  return jobTasks.slice().sort(jobTasksSort)
}

export const calcNeighborRegionNames = (regions: (string | undefined | null)[]) => {
  const unitify = (arr: string[]) => {
    const data = arr.reduce(
      (acc, region) => {
        const [dong, num] = parseRegionName(region)
        if (!dong || typeof num !== 'number') {
          acc[region] = []
          return acc
        }
        acc[dong] = (acc[dong] ?? []).concat(num)
        return acc
      },
      {} as Record<string, number[]>
    )
    return combineKeysAndValues(convertToRanges(data))
  }
  const values = flow(compact, uniq)(regions)

  return {
    stringify: () => unitify(values).join(', '),
    count: values.length,
  }
}

function convertToRanges(obj: Record<string, number[]>) {
  const result = {} as Record<string, string[]>
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const arr = obj[key]

      if (arr.length === 0) {
        result[key] = []
        continue
      }

      const sortedArr = arr.sort((a, b) => a - b)
      const ranges = []
      let [start, end] = [sortedArr[0], sortedArr[0]]

      for (let i = 1; i < sortedArr.length; i++) {
        const num = sortedArr[i]
        if (num === end + 1) {
          end = num
        } else {
          ranges.push(getRangeString(start, end))
          start = num
          end = num
        }
      }
      ranges.push(getRangeString(start, end)) // 마지막 구간 문자열을 추가
      result[key] = ranges
    }
  }
  return result
}

function getRangeString(start: number, end: number) {
  if (start === end) {
    return start.toString() // 구간의 길이가 1인 경우 숫자만 반환
  } else {
    return `${start}~${end}` // 구간의 길이가 2 이상인 경우 시작 값과 끝 값을 '-'로 연결하여 반환
  }
}

// O동, O본동, ON동, O제N동

function combineKeysAndValues(obj: Record<string, string[]>) {
  return Object.entries(obj)
    .sort((aRegion, bRegion) => {
      const [aDong, aNums] = aRegion
      const [bDong, bNums] = bRegion
      const isNeedCustomSort = !!aNums.length !== !!bNums.length
      const [a, b] = aNums.length ? [bDong, aDong] : [aDong, bDong]
      return isNeedCustomSort && a.startsWith(b) && ['동', '본동'].includes(a.slice(b.length))
        ? aDong === a
          ? -1
          : 1
        : aDong.localeCompare(bDong)
    })
    .flatMap(([key, value]) => {
      if (value.length === 0) return key
      return `${key}${value}동`
    })
}

function parseRegionName(region: string) {
  const match = region.match(/(\D+)(\d+)/)
  if (match === null) {
    return [undefined, undefined]
  }

  return [match[1], parseInt(match[2], 10)]
}

const workPeriodToString = (period?: ExperiencePeriod | '%future added value' | null): string => {
  return period && period !== '%future added value'
    ? experiencePeriodMap[period]
    : experiencePeriodMap['LESS_THAN_THREE_MONTH']
}

export const stringifyExperienceDuration = ({
  year,
  period,
}: {
  year: number | null | undefined
  period: ExperiencePeriod | '%future added value' | null | undefined
}) => {
  return `${period?.startsWith('LESS_THAN') ? `${year}년` : `${year}년부터`} ${getMiddleDotStr()} ${workPeriodToString(
    period
  )}`
}

export function checkLastCharHasJongSung(word: string) {
  if (typeof word !== 'string') return null

  const lastLetter = word[word.length - 1]
  const uni = lastLetter.charCodeAt(0)

  if (uni < 44032 || uni > 55203) return null

  return (uni - 44032) % 28 != 0
}

export function getCombinedFilterNames(data: Record<any, any>, exception?: string | string[]) {
  const values = Object.entries(data).filter(([key, val]) => {
    if (typeof exception === 'object') {
      return !exception.includes(key) && val
    }

    return key !== exception && val
  })

  if (values.length === 0) return undefined

  return values.map(([key]) => key).join(',')
}

export function splitDateFrom8DigitString(str?: string | null): {
  year: string | null
  month: string | null
  day: string | null
} {
  if (!str || str.length !== 8) return { year: null, month: null, day: null }

  return str
    .replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3')
    .split('.')
    .reduce(
      (acc, cur, i) => {
        return { ...acc, [i === 0 ? 'year' : i === 1 ? 'month' : 'day']: cur }
      },
      {} as { year: string; month: string; day: string }
    )
}

export function truncate(str: string, maxLength: number, hasEllipsis?: boolean): string {
  if (str.length <= maxLength) return str
  const slicedStr = str.slice(0, maxLength)

  return hasEllipsis ? `${slicedStr}...` : slicedStr
}

export function removeQuotes(text: string) {
  return text.replace(/"/g, '').replace('\b', '')
}

export function getDataStatusType(initialData: unknown[], handledData: unknown[]) {
  const initialLength = initialData.length
  const handledLength = handledData?.length || 0

  if (initialLength > 0 && handledLength === 0) {
    return 'DELETE'
  } else if (initialLength === 0 && handledLength > 0) {
    return 'CREATE'
  } else if (initialLength > 0 && handledLength > 0) {
    if (initialLength !== handledLength || initialData.some((item, index) => !isEqual(item, handledData[index]))) {
      return 'UPDATE'
    }
  }
  return undefined
}

export const formatTimeTo12Hour = (workTime: string) => {
  const [hours, minutes] = workTime.split(':').map(Number)
  const relativeHour = hours <= 12 ? hours : hours - 12

  return `${hours < 12 ? '오전' : '오후'} ${String(relativeHour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}
