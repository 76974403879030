export type TreatmentSymbol = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N'

export type Treament = {
  group: TreatmentSymbol
  percent: number
  description: string
}

// e.g BM_TASK_FEE: 'bm_task_fee'
export const EXPERIMENT_NAMES = {
  NEAR_JOB_POSTS_SECTION: 'near_job_posts_section',
  SUGGEST_UPDATING_JOB_POST_TITLE: 'suggest_updating_job_post_title',
  LARGE_SCALE_BUSINESS_GUIDE: 'large_scale_business_guide_new_user',
} as const

export type ExperimentName = (typeof EXPERIMENT_NAMES)[keyof typeof EXPERIMENT_NAMES]

export interface Experiment {
  name: ExperimentName
  description: string
  version: number
  exclude: number
  control: number
  treatments: Treament[]
  regions?: readonly { name1?: string; name2?: string; name3?: string }[]
  isDynamicAssiginment?: boolean
}

export const EXPERIMENTS: readonly Experiment[] = [
  {
    name: EXPERIMENT_NAMES.NEAR_JOB_POSTS_SECTION,
    description: '걸어서 10분 공고 섹션 노출 실험',
    version: 1,
    exclude: 0,
    control: 50,
    treatments: [{ group: 'A', percent: 50, description: '섹션 노출' }],
  },
  {
    name: EXPERIMENT_NAMES.SUGGEST_UPDATING_JOB_POST_TITLE,
    description: '공고 제목 업데이트 권유 실험',
    version: 1,
    exclude: 0,
    control: 50,
    treatments: [{ group: 'A', percent: 50, description: '공고 제목 업데이트 권유' }],
  },
  {
    name: EXPERIMENT_NAMES.LARGE_SCALE_BUSINESS_GUIDE,
    description: '대규모/상시 안내 실험',
    version: 1,
    exclude: 100,
    control: 0,
    treatments: [{ group: 'A', percent: 0, description: '날짜/예산 조합형 광고 생성' }],
  },
] as const
