import { parse } from 'graphql'
import React from 'react'

import { timeAgo } from '@src/utils/date'

interface Props {
  requests: {
    query: string
    text: string
    variables: any
    response: any
    duration: number
    timestamp: string
  }[]
}

const RelayNetworkVConsoleTab: React.FC<Props> = ({ requests }) => {
  const [openState, setOpenState] = React.useState({} as { [key: string]: boolean })

  return (
    <div className="vc-table">
      <div className="vc-table-head">
        <div className="vc-table-row">
          <div className="vc-table-col vc-table-col-5">name</div>
          <div className="vc-table-col vc-table-col-1">ago</div>
          <div className="vc-table-col vc-table-col-2">perf</div>
        </div>
      </div>
      <div className="vc-table-body">
        {requests.map((req, index) => {
          const foldId = `fold-${index}`
          const handleGroupClick = () => {
            setOpenState((prev) => {
              return {
                ...prev,
                [foldId]: !prev[foldId],
              }
            })
          }

          const definition = parse(req.text).definitions.find((def) => def.kind === 'OperationDefinition')
          const operation = (definition as any)?.operation
          const resolvers = (definition as any)?.selectionSet?.selections
            .filter((sel: any) => sel.kind === 'Field')
            .map((sel: any) => sel.name.value)

          return (
            <React.Fragment key={index}>
              <div className="vc-table-row vc-group" onClick={handleGroupClick}>
                <div className={`vc-table-col vc-table-col-5 ${openState[foldId] ? 'bold' : ''}`}>{req.query}</div>
                <div className="vc-table-col vc-table-col-1">{timeAgo({ date: req.timestamp, maxUnitCount: 1 })}</div>
                <div className="vc-table-col vc-table-col-2">{req.duration}ms</div>
              </div>
              <div className="vc-group-detail" id={foldId} style={{ display: openState[foldId] ? 'block' : 'none' }}>
                <div className="vc-table">
                  <div className="vc-table-body">
                    {resolvers.map((resolver: string) => {
                      const handleClick = (e: React.MouseEvent) => {
                        e.stopPropagation()
                        const url = `https://github.com/search?q=${resolver}%28parent+repo%3Adaangn%2Fjobs+path%3A*.ts+path%3Asrc%2Fgraphql%2Fschema%2F**&type=code&ref=advsearch`
                        window.open(url)
                      }

                      return (
                        <div key={resolver} className="vc-table-row">
                          <div className="vc-table-col vc-table-col-5">{resolver}</div>
                          <div className="vc-table-col vc-table-col-1">{operation}</div>
                          <div
                            className="vc-table-col vc-table-col-2"
                            onClick={handleClick}
                            style={{ cursor: 'pointer' }}>
                            🔎
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className="vc-detail-head-row">variables</div>
                <div className="vc-detail-body">{JSON.stringify(req.variables, null, 2)}</div>
                <div className="vc-detail-head-row">response</div>
                <div className="vc-detail-body">{JSON.stringify(req.response, null, 2)}</div>
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default RelayNetworkVConsoleTab
