import { keys, omit, pick } from 'lodash'

import { ApplicantNationalityType } from '@src/types/application'
import {
  AllJobTaskType,
  JobPostType,
  JobTaskType,
  PersonalJobTaskType,
  SalaryType,
  WorkDay,
  WorkPeriod,
  WorkTime,
} from '@src/types/jobPost'
import { ResumeType, VisaType } from '@src/types/resume'
import { NewJobPostFilterSubscriptionType } from '@src/types/subscription'

import { CURRENT_YEAR } from './date'

export const jobPostIninitialValues: JobPostType = {
  title: '',
  jobTasks: [],
  companyName: null,
  isPersonal: false,
  phone: null,
  isPhoneIncontactable: false,
  workplaceAddress: '',
  workplaceRoadAddress: '',
  workplaceLat: null,
  workplaceLng: null,
  workplaceRequiredActions: null,
  workPeriod: null,
  workDays: ['MON', 'TUE', 'WED', 'THU', 'FRI'],
  workDates: [],
  workTimeStart: '09:00',
  workTimeEnd: '18:00',
  isWorkTimeNegotiable: false,
  salaryType: 'HOURLY',
  salary: null,
  salaryPayMethod: null,
  content: '',
  images: [],
  terms: false,
  authorType: 'HOIAN_USER',
}

export const enterpriseJobPostInitialValues = jobPostIninitialValues

export const resumeInitialValues: ResumeType = {
  name: '',
  name_first: '',
  name_last: '',
  images: [],
  gender: null,
  age: null,
  birthYear: null,
  phone: '',
  experiences: [],
  content: '',
  characters: [],
  extraFeatures: [],
  hasNotExperience: false,
  isForeigner: false,
  nationality: ApplicantNationalityType.DOMESTIC,
  visa: null,
}

export const experiencePeriodMap = {
  LESS_THAN_A_MONTH: '단기',
  LESS_THAN_THREE_MONTH: '3개월 이하',
  LESS_THAN_SIX_MONTH: '6개월 이하',
  MORE_THAN_SIX_MONTH_LESS_THAN_ONE_YEAR: '6개월 ~ 1년',
  MORE_THAN_ONE_YEAR_LESS_THAN_TWO_YEAR: '1년 ~ 2년',
  MORE_THAN_TWO_YEAR_LESS_THAN_THREE_YEAR: '2년 ~ 3년',
  MORE_THAN_THREE_YEAR: '3년 이상',
} as const

export const jobReviewWorkingPeriodTypeMap = {
  ONE_DAY: '하루',
  LESS_THAN_A_MONTH: '한 달 이내',
  MORE_THAN_A_MONTH: '한 달 이상',
} as const

export const visaTypeMap = {
  F2: { form: '거주 (F-2)', display: '거주비자 (F-2)' },
  F4: { form: '재외동포 (F-4)', display: '재외동포비자 (F-4)' },
  F5: { form: '영주 (F-5)', display: '영주비자 (F-5)' },
  F6: { form: '결혼이민 (F-6)', display: '결혼이민비자 (F-6)' },
  H2: { form: '방문 (H-2)', display: '방문비자 (H-2)' },
  D2: { form: '유학 (D-2)', display: '유학비자 (D-2)' },
  D4: { form: '연수 (D-4)', display: '연수비자 (D-4)' },
  E7: { form: '특정활동 (E-7)', display: '특정활동비자 (E-7)' },
  E9: { form: '비전문 (E-9)', display: '비전문비자 (E-9)' },
  OTHER: { form: '기타', display: '비자 (기타)' },
  PENDING: { form: '취득예정', display: '비자 (취득예정)' },
} satisfies Record<VisaType, { form: string; display: string }>

export const mapJobReviewWorkingPeriodType = (
  jobReviewWorkingPeriod: keyof typeof jobReviewWorkingPeriodTypeMap | '%future added value'
) => {
  switch (jobReviewWorkingPeriod) {
    case 'ONE_DAY':
      return jobReviewWorkingPeriodTypeMap['ONE_DAY']
    case 'LESS_THAN_A_MONTH':
      return jobReviewWorkingPeriodTypeMap['LESS_THAN_A_MONTH']
    case 'MORE_THAN_A_MONTH':
      return jobReviewWorkingPeriodTypeMap['MORE_THAN_A_MONTH']
    default:
      return ''
  }
}

export const resumeExperienceInitialValues = {
  workplaceName: '',
  year: CURRENT_YEAR,
  period: 'LESS_THAN_THREE_MONTH' as const,
  content: '',
}

export const workDayValues: WorkDay[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

export const salaryTypeValues: SalaryType[] = ['HOURLY', 'DAILY', 'PER_CASE', 'MONTHLY']

export const workPeriodValues: WorkPeriod[] = ['LESS_THAN_A_MONTH', 'MORE_THAN_A_MONTH', '%future added value']

export const workTimeValues: WorkTime[] = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
]

export const reviewCodeMap = {
  // 구인자/구직자 공통(GOOD)
  FAST_RESPONSE: { text: '답변이 빨라요', positive: true, emoji: '💬' },
  NICE_MANNER: { text: '친절하고 매너가 좋아요', positive: true, emoji: '👍🏼' },

  // 구인자(GOOD)
  RESPECTED: { text: '일하는 사람을 존중해요', positive: true, emoji: '🤝' },
  KEEP_BREAKTIME: { text: '휴게시간을 지켜요', positive: true, emoji: '⏱' },
  GIVE_ALLOWANCE: { text: '주휴수당을 잘 챙겨요', positive: true, emoji: '💰' },
  PAYMENT_INTIME: { text: '급여를 제때 지급해요', positive: true, emoji: '💰' },
  GOOD_ENVIRONMENT: { text: '근무 환경이 좋아요', positive: true, emoji: '🥕' },
  SATISFIED: { text: '일의 보람을 느낄 수 있어요', positive: true, emoji: '🙌' },
  LOTS_OF_LEARNS: { text: '일하면서 배울 점이 많아요', positive: true, emoji: '✍️' },
  SPECIFIC_EXPLANATION: { text: '업무에 대한 안내가 자세해요', positive: true, emoji: '📒️' },
  QUICK_RESPONSE: { text: '연락이 빨라요', positive: true, emoji: '' },
  DETAILED_DESCRIPTION: { text: '일에 대한 설명이 자세해요', positive: true, emoji: '' },
  HAS_BREAK_AREA: { text: '휴게공간이 있어요', positive: true, emoji: '' },
  PROVIDES_MEALS_SNACKS: { text: '식사/간식을 챙겨줘요', positive: true, emoji: '' },
  GOOD_WORKING_ATMOSPHERE: { text: '일하는 분위기가 좋아요', positive: true, emoji: '' },
  SIGNED_EMPLOYMENT_CONTRACT: { text: '근로계약서를 작성했어요', positive: true, emoji: '' },
  APPROPRIATE_WORK_INTENSITY: { text: '업무 강도가 적절해요', positive: true, emoji: '' },
  ENJOYABLE_WORK: { text: '즐겁게 일할 수 있어요', positive: true, emoji: '' },

  // 구직자(GOOD)
  KEEP_TIME: { text: '시간 약속을 잘 지켜요', positive: true, emoji: '⏱' },
  SINCERE: { text: '성실해요', positive: true, emoji: '🙂' },
  MATURE_WORK: { text: '업무에 능숙해요', positive: true, emoji: '🙌' },
  FAST_LERANER: { text: '일을 빨리 배워요', positive: true, emoji: '✍️' },
  KEEP_CONTRACT_PERIOD: { text: '약속한 근무 기간을 채웠어요', positive: true, emoji: '🤝' },

  // 구인자/구직자 공통(BAD)
  NO_ANSWER_CHAT: { text: '채팅을 보내도 답이 없어요', positive: false, emoji: '💬' },

  // 구인자(BAD)
  NOT_RESPECTED: { text: '일하는 사람을 존중하지 않아요', positive: false, emoji: '🤝' },
  IGNORE_BREAKTIME: { text: '휴게시간을 지키지 않아요', positive: false, emoji: '⏱' },
  NO_ALLOWANCE: { text: '주휴수당을 주지 않아요', positive: false, emoji: '💰' },
  DELAY_SALARY: { text: '급여가 밀리거나 지급되지 않아요', positive: false, emoji: '💰' },
  BAD_ENVIRONMENT: { text: '근무 환경이 열악해요', positive: false, emoji: '🕸️' },
  DIFFERENT_WORK: { text: '설명과 다른 일을 해요', positive: false, emoji: '🤔' },
  DIFFERENT_SALARY: { text: '급여가 알바공고 내용과 달라요', positive: false, emoji: '💰' },
  DIFFERENT_WORKPLACE_ADDRESS: { text: '갑자기 근무지가 바뀌었어요', positive: false, emoji: '🏢' },
  DISEASE: { text: '불건전 업체에요', positive: false, emoji: '️🚫' },
  CHANGED_TASK_OR_LOCATION: { text: '할 일이나 장소가 달라졌어요', positive: false, emoji: '' },
  DID_NOT_HONOR_CONTRACT: { text: '계약 내용을 지키지 않았어요', positive: false, emoji: '' },

  // 구직자(BAD)
  BREAK_TIME: { text: '시간 약속을 안 지켜요', positive: false, emoji: null },
  BAD_MANNER: { text: '매너가 좋지 않아요', positive: false, emoji: null },
  NOT_SINCERE: { text: '성실하지 않아요', positive: false, emoji: null },
  IMMATURE_WORK: { text: '업무에 미숙해요', positive: false, emoji: null },
  SLOW_LEARNER: { text: '일을 배우는 속도가 느려요', positive: false, emoji: null },
  BREAK_CONTRACT_PERIOD: { text: '약속한 근무 기간을 채우지 않았어요', positive: false, emoji: null },
  BREAK_APPOINTMENT: { text: '약속 장소에 나타나지 않았어요', positive: false, emoji: null },
  UNKIND: { text: '불친절해요', positive: false, emoji: null },
}

function keyMap<K extends string>(obj: Record<K, any>): Record<K, K> {
  return Object.keys(obj).reduce((acc, cur) => Object.assign(acc, { [cur]: cur }), {} as any)
}

export const reviewCodes = keyMap(reviewCodeMap)

export const allJobTasksMap: Record<AllJobTaskType, string> = {
  SERVING: '서빙',
  KITCHEN_ASSISTANCE: '주방보조/설거지',
  KITCHEN_COOK: '주방장/조리사',
  SHOP_MANAGEMENT: '매장관리/판매',
  BEVERAGE_MAKING: '음료 제조',
  BAKING: '베이킹',
  CONVENIENCE_STORE: '편의점',
  MOVING_ASSISTANCE: '짐 옮기기',
  CLEANING: '업체청소',
  LIGHT_WORK: '심부름/소일거리',
  LEAFLET_DISTRIBUTION: '전단지 배포',
  TUTORING: '과외/학원',
  KIDS_PICK_UP: '등하원도우미',
  CHILD_CARE: '아이돌봄',
  PET_CARE: '반려동물 돌봄',
  SENIOR_CARE: '어르신 돌봄',
  HOUSEWORK: '가사/집정리',
  ETC: '기타',
  NURSING: '돌봄/시팅',
}

export const jobTasksMap: Record<JobTaskType, string> = omit(allJobTasksMap, ['NURSING'] as const)

export const personalJobTasksMap: Record<PersonalJobTaskType, string> = pick(allJobTasksMap, [
  'MOVING_ASSISTANCE',
  'HOUSEWORK',
  'KIDS_PICK_UP',
  'CHILD_CARE',
  'PET_CARE',
  'SENIOR_CARE',
  'LIGHT_WORK',
  'TUTORING',
  'ETC',
] as const)

export const jobTaskValues = keys(jobTasksMap) as JobTaskType[]
export const personalJobTaskValues = keys(personalJobTasksMap) as PersonalJobTaskType[]

export const newJobPostFilterSubscriptionIninitialValues: NewJobPostFilterSubscriptionType = {
  workPeriods: [],
  jobTasks: [],
  workDays: ['MON', 'TUE', 'WED', 'THU', 'FRI'],
  regions: [],
  isHolding: null,
}

export type Welfare =
  // 4대 보험
  | 'INSURANCE'
  // 주휴수당
  | 'PAID_VACATION'
  // 휴일 근로수당
  | 'HOLIDAY_WORKING_ALLOWANCE'
  // 연차
  | 'ANNUAL_LEAVE'
  // 퇴직금
  | 'RETIREMENT_BENEFIT'
  // 휴게시간 보장
  | 'REST_TIME_GUARANTEE'
  // 식사 제공
  | 'MEAL_PROVIDED'

export type ConsideredQualification =
  // 초보 가능
  | 'BEGINNER'
  // 외국인 가능
  | 'FOREIGNER'
  // 성인 전연령대
  | 'ADULT_ALL_AGES'
  // 미성년자 가능
  | 'MINOR'

export const consideredQualificationsMap: Record<ConsideredQualification, string> = {
  BEGINNER: '초보 가능',
  FOREIGNER: '외국인 가능',
  ADULT_ALL_AGES: '나이제한 없음',
  MINOR: '미성년자 가능',
}

export const welfareMap: Record<Welfare, string> = {
  PAID_VACATION: '주휴수당',
  HOLIDAY_WORKING_ALLOWANCE: '휴일근로수당',
  ANNUAL_LEAVE: '연차',
  MEAL_PROVIDED: '식사 제공',
  REST_TIME_GUARANTEE: '휴게시간 보장',
  INSURANCE: '4대 보험',
  RETIREMENT_BENEFIT: '퇴직금',
}

export const characterMap = {
  DILIGENCE: '성실해요',
  KINDNESS: '친절해요',
  THOROUGHNESS: '일처리가 꼼꼼해요',
  INITIATIVE: '적극적이에요',
  PUNCTUALITY: '지각하지 않아요',
  QUICK_LEARNER: '일을 빨리 배우는 편이에요',
  STAMINA: '체력이 좋아요',
  AGILITY: '손이 빨라요',
  CLEAN: '깔끔하고 청결해요',
  RESPONSIBILITY: '책임감이 있어요',
  RELIABILITY: '약속을 잘 지켜요',
  HEALTH: '신체가 건강해요',
  CHEERFULNESS: '성격이 밝아요',
  POLITENESS: '예의가 발라요',
  POSITIVITY: '긍정적이에요',
  TACTFULNESS: '센스 있어요',
}

export const extraFeatureMap = {
  HEALTH_CERTIFICATE: '보건증 있음',
  NON_SMOKER: '비흡연',
  LONG_TERM_WORK_POSSIBLE: '장기근무 가능',
  COOKING_SKILLS: '요리 능숙',
  COOKING_CERTIFICATE: '조리자격증',
  CAR_LICENSE: '자동차 면허',
  ENGINE_LICENSE: '원동기 면허',
  OWN_CAR: '차량 있음',
  PET_CARE_EXPERIENCE: '반려동물 양육 경험 있음',
  PARENTING_EXPERIENCE: '육아 경험 있음',
  LANG_ENGLISH: '영어 가능',
  FLUENT_KOREAN: '한국어 능통',
  COMPUTER_SKILLS: '컴퓨터 능숙',
  MILITARY_SERVICE_COMPLETED_OR_EXEMPTED: '군필/면제',
  NURSING_ASSISTANT_CERTIFICATE: '요양보호사 자격증',
  MARTIAL_ARTS_DAN: '무술 유단',
}
